import { useEffect, useState } from 'react'
import { api_prod } from '../../services/api'

export const ButtonHypnobox = () => {
  const [url, setUrl] = useState('')
  async function getUrlHypnoBox() {
    try {
      const response = await api_prod.get('/hypnobox-link?venture_id=2')

      setUrl(response.data.results.link)
      console.log(response)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUrlHypnoBox()
  }, [])

  return (
    <a href={url} rel="noreferrer" target="_blank">
      <div className="btn-hypno">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="4.5em"
          height="4.5em"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M12 21.25a9.25 9.25 0 1 0-8.307-5.177c.108.22.144.468.089.706l-.816 3.536a.6.6 0 0 0 .72.72l3.535-.817a1.06 1.06 0 0 1 .706.09A9.2 9.2 0 0 0 12 21.25M7.97 9.886h8.06m-8.06 4.228h5.748"
          ></path>
        </svg>
      </div>
    </a>
  )
}

